export const main = "rgb(38,41,54)";
export const main_l = "rgb(55, 57, 70)";
export const main_d = "rgb(30, 32, 43)";
export const mutedMain = "#303443";

export const gray = "#a8b3dc";
export const gray_l = "#B5BBD6";
export const gray_d = "#5d647f";
export const shadow = "0px 2px 6px rgba(0, 0, 0, 0.1)";
export const gray_alt = "#82888a";

export const red = "#f63b34";
export const blue = "#02adc3";
export const blue_d = "#018a9c";
export const green = "#78a303";
export const yellow = "#EEB902";
export const orange = "#F45D01";
export const farmerColors = [
  "#7EB77F",
  "#5CA4A9",
  "#235789",
  "#631A86",
  "#9381FF",
  "#B5446E",
  "#E5E059",
  "#6CCFF6",
  "#FFE347"
];

export const HEX2RGB = hex => {
  try {
    let newHex;
    if (hex.charAt(0) === "#") {
      newHex = hex.substr(1);
    }

    const values = newHex.split("");
    let r = parseInt(values[0].toString() + values[1].toString(), 16);
    let g = parseInt(values[2].toString() + values[3].toString(), 16);
    let b = parseInt(values[4].toString() + values[5].toString(), 16);

    return [r, g, b];
  } catch (err) {
    console.log("error in reading hex", err.message);
  }
};

export const alertColors = [green, blue, yellow, orange, red];
