import { iphone, ipad } from "components/UI/mediaQueries";
import {
  borderRadius,
  small,
  halfSmall,
  maxWidth,
  height
} from "components/UI/sizes";

import {
  main,
  mutedMain,
  gray,
  gray_l,
  gray_d,
  shadow,
  red,
  blue,
  green,
  yellow,
  orange
} from "components/UI/colors";

const theme = {
  colors: {
    main,
    mutedMain,
    gray,
    gray_l,
    gray_d,
    red,
    blue,
    green,
    yellow,
    orange
  },
  media: { iphone, ipad },
  borderRadius,
  small,
  halfSmall,
  maxWidth,
  height,
  shadow
};

export default theme;
