import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";

const link =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://nemo-manager.observe.tech/graphql" 
    : process.env.REACT_APP_ENVIRONMENT === "release"
    ? "https://nemo-manager-beta.observe.tech/graphql"
    : "https://nemo-manager.dev.observe.tech/graphql";
const defaultOptions = {
  // watch: {
  //   fetchPolicy: "no-cache",
  //   errorPolicy: "all"
  // },
  // query: {
  //   fetchPolicy: "no-cache",
  //   errorPolicy: "all"
  // },
  // mutate: {
  //   errorPolicy: "all"
  // }
};

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token") || null;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "x-token": token,
      version: 1
    }
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(
    createHttpLink({
      uri: link
    })
  ),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions
});
