import gql from "graphql-tag";

export default gql`
  mutation($email: String!, $company: String!) {
    followUpEmail(email: $email, company: $company) {
      ok
      error
    }
  }
`;
