export default {
  format: {
    time: "HH:mm",
    date: "YYYY-MM-DD",
    datetime: "YYYY-MM-DD HH:mm:ss",
    wordedDate: "Do MMM YYYY"
  },
  name: {
    cage: {
      singular: "Unit",
      plural: "Units"
    }
  }
};
