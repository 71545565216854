import React from "react";
import { Container, Header, Form, Button, Input } from "semantic-ui-react";
import styled from "styled-components";
import { Label } from "../../../components/UI/inputs";
import { blue, shadow } from "../../../components/UI/colors";

export default ({
  emptyFields,
  submitError,
  email,
  password,
  loading,
  onChange,
  onSubmit
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      boxShadow: shadow
    }}
  >
    <div className="login-form-wrapper">
      <StyledContainer>
        <Header
          as="h1"
          textAlign="center"
          style={{ color: "#fff", marginTop: "1rem" }}
        >
          Login
        </Header>
        <Header.Subheader
          style={{ color: "#fff", padding: "0.5rem 0", fontSize: 20 }}
        >
          {emptyFields ? "Input fields can not be empty" : "Welcome back!"}
        </Header.Subheader>
        <Form>
          <Form.Field>
            <Label>{"Email"}</Label>
            <Input
              fluid
              onChange={onChange}
              name="email"
              id="email"
              value={email}
              placeholder="Email"
              onError={submitError || emptyFields}
            />
          </Form.Field>
          <Form.Field>
            <Label>{"Password"}</Label>
            <Input
              fluid
              onChange={onChange}
              name="password"
              id="password"
              value={password}
              placeholder="Password"
              type="password"
              onError={submitError || emptyFields}
            />
          </Form.Field>
          <Form.Field>
            <Button
              size="small"
              fluid
              className={
                submitError ? "login-button" : "login-button submit-error"
              }
              onClick={onSubmit}
              loading={loading}
              disabled={!email && !password}
              negative={submitError}
              style={{ background: !submitError && blue, color: "white" }}
            >
              {submitError ? "Error submiting your details" : "Submit"}
            </Button>
          </Form.Field>
        </Form>
      </StyledContainer>
    </div>
  </div>
);

const StyledContainer = styled(Container)`
  &.ui.container {
    margin: 0px auto;
    background: rgb(39, 42, 53);
    padding: 2rem;
    border-radius: 0.285714rem;
    max-width: 500px !important;
    width: 50% !important;
    min-width: 370px !important;
    box-shadow: 0px 10px 60px rgba(2, 173, 195, 0.1);
  }

  @media only screen and (max-width: 767px) {
    &.ui.container {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
`;
