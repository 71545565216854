import React from "react";
import { Container, Form, Grid } from "semantic-ui-react";
import { Button, Input } from "@observe-tech/ui-components";
import { Label } from "components/UI/inputs";

export default ({ keys, onChange, onSubmit, children, errors }) => {
  const {
    email,
    password,
    rePassword,
    lastName,
    firstName,
    phoneNumber,
    loading
  } = keys;
  const {
    emailError,
    passwordError,
    lastNameError,
    firstNameError,
    phoneNumberError
  } = errors;
  return (
    <Container
      style={{
        background: "#272A35",
        padding: "2rem 1rem",
        borderRadius: ".28571429rem"
      }}
    >
      {children}
      <Form>
        <Grid stackable columns={2} padded>
          <Grid.Column style={{ height: "100%" }}>
            <Form.Field>
              <Label>{emailError || "Email"}</Label>
              <Input
                fluid
                onChange={onChange}
                name="email"
                value={email}
                placeholder="Email"
                type="email"
                onError={emailError}
              />
            </Form.Field>
            <Form.Field>
              <Label>{passwordError || "Password"}</Label>
              <Input
                fluid
                onChange={onChange}
                name="password"
                value={password}
                placeholder="Password"
                type="password"
                onError={passwordError}
              />
            </Form.Field>
            <Form.Field>
              <Label>{passwordError || "Repeat Password"}</Label>
              <Input
                fluid
                onChange={onChange}
                name="rePassword"
                value={rePassword}
                placeholder="Repeat Password"
                type="password"
                onError={passwordError}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column style={{ height: "100%" }}>
            <Form.Field>
              <Label>{firstNameError || "First name"}</Label>
              <Input
                fluid
                onChange={onChange}
                name="firstName"
                value={firstName}
                placeholder="First name"
                onError={firstNameError}
              />
            </Form.Field>

            <Form.Field>
              <Label>{lastNameError || "Last name"}</Label>
              <Input
                fluid
                onChange={onChange}
                name="lastName"
                value={lastName}
                placeholder="Last name"
                onError={lastNameError}
              />
            </Form.Field>

            <Form.Field>
              <Label>{phoneNumberError || "Telephone number"}</Label>
              <Input
                fluid
                onChange={onChange}
                name="phoneNumber"
                value={phoneNumber}
                placeholder="Telephone number"
                onError={phoneNumberError}
              />
            </Form.Field>
          </Grid.Column>
          <Button
            loading={loading}
            className="register-submit"
            fluid
            onClick={onSubmit}
            style={{ margin: "0 1rem" }}
          >
            Submit
          </Button>
        </Grid>
      </Form>
    </Container>
  );
};
