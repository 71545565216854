import React from "react";
import { graphql } from "react-apollo";
import { Header } from "semantic-ui-react";
import followUpEmail from "gql/user/user.followUpEmail.mutation";
import Form from "./components/Form";

class Register extends React.Component {
  state = {
    email: "",
    password: "",
    rePassword: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emptyFields: false,
    loading: false,
    submitError: false,
    errors: {},
    authenticated: false
  };

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  validateInputs = ({
    email,
    password,
    rePassword,
    lastName,
    firstName,
    phoneNumber
  }) => {
    const values = {
      email,
      password,
      rePassword,
      lastName,
      firstName,
      phoneNumber
    };
    let okay = true;
    Object.keys(values).forEach(key => {
      if (!values[key]) {
        console.log("key is missing");
        okay = false;
      }
    });
    delete values.rePassword;
    return okay && values;
  };

  onSubmit = async () => {
    try {
      this.setState({ submitError: false });
      const values = this.validateInputs(this.state);
      if (!values) {
        return;
      }
      let response = null;
      this.setState({ loading: true });
      try {
        response = await this.props.mutate({
          variables: { ...values, company: this.props.match.params.hash }
        });
      } catch (err) {
        this.setState({ loading: false, submitError: true });
        console.log(err);
      }

      const { ok } = response.data.followUpEmail;
      if (ok) {
        this.setState({ loading: false });
        this.props.history.push("/login");
      } else {
        this.setState(prevState => ({
          loading: false,
          submitError: true
        }));
      }
    } catch (err) {
      this.setState(prevState => ({
        loading: false,
        submitError: true
      }));
    }
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <div>
          <Form
            keys={{ ...this.state }}
            errors={this.state.errors}
            onSubmit={this.onSubmit}
            onChange={this.onChange}
          >
            <Header
              as="h1"
              textAlign="center"
              style={{ color: "#fff", marginTop: "1rem" }}
            >
              Register
              <Header.Subheader style={{ color: "#fff", padding: "0.5rem 0" }}>
                Enter your details so you can create a new account and start
                managing your company
              </Header.Subheader>
            </Header>
          </Form>
        </div>
      </div>
    );
  }
}

export default graphql(followUpEmail)(Register);
