import React, { Component } from "react";
import { Loader } from "semantic-ui-react";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();
      this.setState({
        component: component
      });
      const isLoaded = localStorage.getItem("loaded");
      if (!isLoaded) {
        await localStorage.setItem("loaded", true);
        window.location.reload(true);
      }
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
}
