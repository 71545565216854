import React from "react";
import { graphql } from "react-apollo";
import { Container, Header, Form } from "semantic-ui-react";
import styled from "styled-components";
import { Button, Input } from "@observe-tech/ui-components";
import sendEmail from "gql/user/user.sendemail.mutation";
import { Label } from "components/UI/inputs";

class CreateUserCredentials extends React.Component {
  state = {
    email: "",
    company: "",
    emptyFields: false,
    loading: false,
    submitError: false
  };
  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value, submitError: false });
  };
  onSubmit = async () => {
    try {
      const { email, company } = this.state;
      if (!email || !company) {
        this.setState({ emptyFields: true });
        return;
      }
      this.setState({ loading: true, submitError: false });
      const response = await this.props.mutate({
        variables: { email, company }
      });
      const { ok, error } = response.data.followUpEmail;
      if (ok) {
        this.setState({ loading: false });

        this.props.history.push("/");
      } else if (error) {
        this.setState({ loading: false, submitError: true });
      }
    } catch (err) {
      console.log(err.message);
      this.setState({ loading: false, submitError: true });
    }
  };
  render() {
    const { email, password, company, loading, submitError } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <div>
          <StyledContainer>
            <Header
              as="h2"
              textAlign="center"
              style={{ color: "#fff", marginTop: "1rem" }}
            >
              Send a Comfirmation Link for a New User
            </Header>
            <Form>
              <Form.Field>
                <Label>Email</Label>
                <Input
                  fluid
                  onChange={this.onChange}
                  name="email"
                  value={email}
                  placeholder="Email"
                  onError={submitError}
                />
              </Form.Field>
              <Form.Field>
                <Label>Company Name</Label>
                <Input
                  fluid
                  onChange={this.onChange}
                  name="company"
                  value={company}
                  placeholder="Company"
                  onError={submitError}
                />
              </Form.Field>
              <Button
                size="big"
                fluid
                onClick={this.onSubmit}
                loading={loading}
                disabled={!email && !password}
              >
                Submit
              </Button>
            </Form>
          </StyledContainer>
        </div>
      </div>
    );
  }
}

export default graphql(sendEmail)(CreateUserCredentials);

const StyledContainer = styled(Container)`
  &.ui.container {
    margin: 0px auto;
    background: rgb(39, 42, 53);
    padding: 2rem;
    border-radius: 0.285714rem;
    max-width: 500px !important;
    width: 50% !important;
    min-width: 370px !important;
  }

  @media only screen and (max-width: 767px) {
    &.ui.container {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
`;
