import React from "react";
import { graphql } from "react-apollo";
import LoginForm from "../components/LoginForm";
import Map from "components/UI/Map";
import { MapWrapper, Wrapper } from "../../layout/index";
import Logo from "components/UI/ObserveIcon";
import login from "gql/user/user.login.mutation";
import { getRoute } from "components/RouteLogic";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emptyFields: false,
      loading: false,
      submitError: false
    };
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value, [`${name}Error`]: false });
  };

  onSubmit = async () => {
    try {
      const { email, password } = this.state;
      if (!email || !password) {
        this.setState({ emptyFields: true });
        return;
      }
      this.setState({ loading: true, submitError: false });
      const response = await this.props.mutate({
        variables: { email, password }
      });

      const { ok, token } = response.data.login;
      if (ok) {
        this.setState({ loading: false });
        await localStorage.setItem("token", token);
        // console.log('route', getRoute())
        this.props.history.push(getRoute());
      } else {
        this.setState({ loading: false, submitError: true });
      }
    } catch (err) {
      this.setState({ loading: false, submitError: true });
    }
  };

  render() {
    return (
      <div style={{ width: "100vw", height: "100vh" }}>
        <MapWrapper>
          <Map markers={[]} />
        </MapWrapper>
        <Wrapper>
          <LoginForm
            {...this.state}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
          />
          <Logo />
        </Wrapper>
      </div>
    );
  }
}

export default graphql(login)(Login);
