import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import { maxWidth } from "../../components/UI/sizes";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgb(34, 36, 48);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const MapWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: rgba(34, 36, 48, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 1rem;
`;

export const WrapperInner = styled(Grid)`
  width: 100% !important;
  max-width: ${maxWidth}px !important;
  height: 5%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin: 0 !important;
  padding: 0 !important;
  @media ${props => props.theme.media.ipad.landscape} {
    height: 10%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${maxWidth}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  box-sizing: border-box;
`;
