import React from "react";
import "semantic-ui-css/semantic.min.css";
import "react-loading-bar/dist/index.css";
import "./index.css";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router
} from "react-router-dom";
import { isAuthenticated } from "components/RouteLogic";
import asyncComponent from "components/AsyncComponent";

import Login from "routes/User/Login";
import Register from "routes/User/Register";
import CreateUserCredentials from "routes/User/CreateUserCredentials";

const AsyncV2_Desktop = asyncComponent(() => import("./tiers/tier2/desktop"));
const AsyncV2_Mobile = asyncComponent(() => import("./tiers/tier2/mobile"));

const AuthenticatedRoute = ({ component: Component, version, ...rest }) => (
  <Route
    {...rest}
    render={routeProps =>
      isAuthenticated(version) ? (
        <Component {...routeProps} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

class App extends React.Component {
  state = {
    isAdmin: false
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <AuthenticatedRoute
              version={2}
              path="/tier2/desktop"
              component={AsyncV2_Desktop}
            />
            <AuthenticatedRoute
              version={2}
              path="/tier2/mobile"
              component={AsyncV2_Mobile}
            />
            <Route path="/login" component={Login} />
            <Route path="/register/:hash" component={Register} />
            <Route path="/createuser" component={CreateUserCredentials} />
            <Route path="*" render={() => <Redirect to="/login" />} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
