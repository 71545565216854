import React from "react";
import { Popup } from "react-leaflet";
import { Header, Icon } from "semantic-ui-react";
import { Button } from "@observe-tech/ui-components";
import { withRouter } from "react-router-dom";
import getRange from "utils/getRange";

export default withRouter(({ name, onOpen, history, siteLocation }) => (
  <Popup onOpen={onOpen}>
    <div style={styles.container}>
      <Header as="h2" style={styles.header}>
        {name}
      </Header>
      <Button
        fluid
        onClick={() =>
          history.push(
            `${siteLocation}/overview/${name}?${getRange({
              rangeStart: "1W",
              compose: true
            })}`
          )
        }
      >
        Details <Icon name="chevron circle right" />
      </Button>
    </div>
  </Popup>
));

const styles = {
  container: { minHeight: "6rem", position: "relative" },
  header: { color: "#fff", marginTop: "1rem" },
  list: {
    marginTop: "0.5rem",
    marginBottom: "1rem",
    borderBottom: "1px solid rgba(255,255,255,0.2)",
    paddingBottom: "0.75rem"
  },
  listItem: {
    color: "white",
    fontSize: "1.2rem"
  },
  caretIcon: negativeIsBad => ({
    color: negativeIsBad ? "red" : "green",
    marginRight: "0.5rem"
  }),
  marginLeft: { marginLeft: "1rem" },
  errorContainer: {
    color: "white",
    fontSize: "1.2rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center"
  }
};
