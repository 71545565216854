import React from "react";
import styled from "styled-components";
import logoAlt from "../../assets/images/logoAlt.svg";

export default () => <Logo src={logoAlt} />;

const Logo = styled.img`
  width: 125px;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
  z-index: 1001;
  padding: 0;
`;
