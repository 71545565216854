import gql from "graphql-tag";

export default gql`
  mutation(
    $email: String
    $password: String
    $firstName: String
    $lastName: String!
    $phoneNumber: String!
    $company: String!
  ) {
    followUpEmail(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      company: $company
    ) {
      ok
      error
    }
  }
`;
