import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as HooksApolloProvider } from "@apollo/react-hooks";

import App from "./Routes";
import { apolloClient } from "./state/apollo";
import theme from "./state/theme";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={apolloClient}>
      <HooksApolloProvider client={apolloClient}>
        <App />
      </HooksApolloProvider>
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
