import React from "react";
import { Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Marker from "./components/Marker";
import mapUrl from "./mapUrl";
import "./styles.css";

class BaseMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = { position: [51.505, -0.09], zoom: 12, markers: [] };
  }

  getMapCenter = markers => {
    const { length } = markers;
    if (!length) return;
    if (length === 1) {
      return this.setState({
        position: [markers[0].lat, markers[0].lng],
        zoom: 12
      });
    }
    const zoom_max_min = markers.reduce(
      (b, a) => ({
        min_lat: b.min_lat < a.lat ? b.min_lat : a.lat,
        min_lng: b.min_lng < a.lng ? b.min_lng : a.lng,
        max_lat: b.max_lat > a.lat ? b.max_lat : a.lat,
        max_lng: b.max_lng > a.lng ? b.max_lat : a.lng
      }),
      {
        min_lat: Infinity,
        max_lat: -Infinity,
        min_lng: Infinity,
        max_lng: -Infinity
      }
    );
    const diff = {
      lat: zoom_max_min["max_lat"] - zoom_max_min["min_lat"],
      lng: zoom_max_min["max_lng"] - zoom_max_min["min_lng"]
    };
    const biggest_distance =
      diff["lng"] > diff["lat"] ? diff["lng"] : diff["lat"];
    const zoom = 0.02 * biggest_distance - 0.5;
    const lat = Number(
      (zoom_max_min["max_lat"] + zoom_max_min["min_lat"]) / 2
    ).toFixed(2);
    const lng = Number(
      (zoom_max_min["max_lng"] + zoom_max_min["min_lng"]) / 2
    ).toFixed(2);
    this.setState({ position: [parseFloat(lat), parseFloat(lng)], zoom });
  };

  formatMarkers = markers =>
    this.setState({
      markers: markers.map(marker => ({
        position: [marker.lat, marker.lng],

        ...marker
      }))
    });

  componentDidMount() {
    const { markers } = this.props;

    this.getMapCenter(markers);
    this.formatMarkers(markers);
  }

  render() {
    const { position, markers } = this.state;
    const { siteLocation } = this.props;
    if (!position.length) {
      return (
        <Map
          center={position}
          zoom={this.state.zoom}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer url={mapUrl} />
        </Map>
      );
    }

    return (
      <Map
        center={position}
        zoom={this.state.zoom}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer url={mapUrl} />
        {markers.map(marker => (
          <Marker key={marker.name} {...marker} siteLocation={siteLocation} />
        ))}
      </Map>
    );
  }
}

export default BaseMap;
