const phone = 480;
const tablet = 768;
const small = 1024;
const medium = 991;
const large = 1300;

const nav = 60;
const smallNav = 45;

export const ipadHeight = 1024;
export const ipadWidth = tablet;

export const iphoneStandardW = 375;
export const iphoneStandardH = 667;
export const iphoneWidth = 375;
export const iphoneHeight = 812;
export const iphoneWidthPlus = 414;
export const iphoneHeightPlus = 736;

const ipadInLandscape = `only screen and (max-width: ${ipadHeight}px) and (max-height: ${ipadWidth}px)`;
const ipadInPortrait = `only screen and (max-width: ${ipadWidth}px) and (max-height: ${ipadHeight}px)`;

const iphoneInPortrait = `only screen and (max-width: ${iphoneWidth}px)  and (max-height: ${iphoneHeight}px)`;

const iphoneStandardPortrait = `only screen and (max-width: ${iphoneStandardW}px)  and (max-height: ${iphoneStandardH}px)`;
const iphonePlusPortrait = `only screen and (max-width: ${iphoneWidthPlus}px)  and (max-height: ${iphoneHeightPlus}px)`;

const iphone = {
  portrait: iphoneInPortrait,
  landscape: ipadInLandscape,
  width: iphoneWidth,
  height: iphoneHeight,
  standard: {
    portrait: iphoneStandardPortrait
  },
  plus: {
    portrait: iphonePlusPortrait,
    width: iphoneWidthPlus
  }
};

const ipad = {
  landscape: ipadInLandscape,
  portrait: ipadInPortrait,
  width: ipadWidth,
  height: ipadHeight
};

export { phone, tablet, small, medium, large, nav, smallNav, ipad, iphone };
