import moment, { Moment } from "moment";
import config from "./config";

export const ranges = {
  "1D": 1,
  "3D": 3,
  "1W": 7,
  "1M": 28
};

type Ranges = keyof typeof ranges;

interface GetRangeProps {
  rangeStart: Ranges | Moment;
  rangeEnd: Ranges | Moment;
  compose: boolean;
  fromThisWeek: boolean;
}

export default ({
  rangeStart,
  rangeEnd,
  compose,
  fromThisWeek
}: GetRangeProps) => {
  const today = moment();

  function getStart(amount: number, date = today.clone()) {
    if (fromThisWeek) {
      return date.startOf("week");
    }

    return moment(date).subtract(amount - 1, "days");
  }

  function getEnd(amount: number, date = today.clone()) {
    if (fromThisWeek) {
      return date.startOf("week").add(amount - 1, "days");
    }

    return moment(date);
  }

  if (!ranges[rangeStart as Ranges]) {
    const start = getStart(1, rangeStart as Moment);
    const end = getEnd(1, (rangeEnd || rangeStart) as Moment);

    if (compose) {
      const params = new URLSearchParams();
      params.set("rangeStart", start.format(config.format.datetime));
      params.set("rangeEnd", end.format(config.format.datetime));
      return decodeURIComponent(params.toString());
    }

    return {
      rangeStart: start,
      rangeEnd: end
    };
  }

  const start = getStart(ranges[rangeStart as Ranges]);
  const end = getEnd(ranges[rangeStart as Ranges]);

  if (compose) {
    const params = new URLSearchParams();
    params.set(
      "rangeStart",
      start.startOf("day").format(config.format.datetime)
    );
    params.set("rangeEnd", end.endOf("day").format(config.format.datetime));
    return decodeURIComponent(params.toString());
  }

  return {
    rangeStart: start.startOf("day"),
    rangeEnd: end.endOf("day")
  };
};
