import decode from "jwt-decode";
import MobileDetect from "mobile-detect";
import { apolloClient } from "state/apollo";

const detect = new MobileDetect(window.navigator.userAgent);

export const getRoute = () => {
  const token = localStorage.getItem("token");

  if (!token) return "/login";

  try {
    const jwt = decode(token);
    var currentTime = Date.now() / 1000;

    if (jwt.exp < currentTime) {
      throw new Error();
    }

    const tier = jwt.data.paid_version;
    const screen = detect.mobile() && !detect.tablet() ? "mobile" : "desktop";

    apolloClient.resetStore();

    return `/tier${tier}/${screen}/dashboard`;
  } catch (err) {
    console.error(err);
  }

  return "/login";
};

export const isAuthenticated = version => {
  const token = localStorage.getItem("token");

  if (!token) return;

  try {
    const jwt = decode(token);
    var currentTime = Date.now() / 1000;
    if (jwt.exp < currentTime || jwt.data.paid_version !== version) {
      return false;
    }
  } catch (err) {
    return false;
  }

  return true;
};
