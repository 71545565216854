import React from "react";
import Leaflet from "leaflet";
import { Marker } from "react-leaflet";
import Popup from "./Popup";
import cageIcon from "assets/images/cage.png";

const icon = new Leaflet.Icon({
  iconUrl: cageIcon,
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new Leaflet.Point(32, 27),
  className: "leaflet-div-icon"
});

class PopupMarker extends React.Component {
  render() {
    const { position, name, siteLocation } = this.props;
    return (
      <Marker position={position} icon={icon}>
        <Popup {...this.state} name={name} siteLocation={siteLocation} />
      </Marker>
    );
  }
}

export default PopupMarker;
